<template>
	<div :style="buyBg">
		<Header :title="$t('assets.buyHk')"></Header>
		
		<div class="column-center-content main-content">
			<div class="row-content" style="width: 100%; height: 60px;">
				<router-link to="/buyHkForSelf" class="row-content shadow-box-content list-box-content" style="justify-content: space-between;">
					<div class="row-content">
						<i class="fa fa-arrows-h" aria-hidden="true" style="margin: 0px 15px; color: #009245;"></i>
						<label class="gray-text-title">{{$t('assets.buyHkForSelf')}}</label>
					</div>
					<i class="fa fa-chevron-right" aria-hidden="true" style="color: #009245;"></i>
				</router-link>
			</div>
			
			<div class="row-content" style="width: 100%; height: 60px; margin-top: 15px">
				<router-link to="/buyHkForOther" class="row-content shadow-box-content list-box-content" style="justify-content: space-between;">
					<div class="row-content">
						<i class="fa fa-arrows-h" aria-hidden="true" style="margin: 0px 15px; color: #009245;"></i>
						<label class="gray-text-title">{{$t('assets.buyHkForOther')}}</label>
					</div>
					<i class="fa fa-chevron-right" aria-hidden="true" style="color: #009245;"></i>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../../../components/NavHeader.vue'
	
	export default {
		name: 'BuyHongKong',
		components: {
			Header
		},
		data:() => ({
			buyBg : {
				height: "100vh",
				width: "100%",
				backgroundImage: "url(" + require("../../../../assets/login/new-bg.png") + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "fixed",
				backgroundSize: "100% 100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "auto"
			}
		})
	}
</script>

<style>
</style>
